webshop.ProductView =  class {
	/* Options:
		- View Type
		- Products Section Wrapper,
		- Item Group: If its an Item Group page
	*/
	constructor(options) {
		console.log('startinggg..')
		Object.assign(this, options);
		this.preference = this.view_type;
		this.make();
		
	}

	make(from_filters=false) {
		this.products_section.empty();
		this.prepare_toolbar();
		this.get_item_filter_data(from_filters);
	}

	prepare_toolbar() {
		this.products_section.append(`
			<div class="toolbar d-flex">
			</div>
		`);
		//this.prepare_search(); //Moved to top so this not required here anymore
		this.prepare_view_toggler();
		//new webshop.ProductSearch();//Moved to top so this not required here anymore
	}

	prepare_view_toggler() {

		if (!$("#list").length || !$("#image-view").length) {
			this.render_view_toggler();
			this.bind_view_toggler_actions();
			this.set_view_state();
		}
	}

	get_item_filter_data(from_filters=false) {
		console.time('Getting Filtered Data')
		// Get and render all Product related views
		let me = this;
		this.from_filters = from_filters;
		console.log('from_filters',from_filters)
		let args = this.get_query_filters();
		console.log('send',args)
		// if (!args.item_group && args.field_filters.item_group && args.field_filters.item_group.length > 1) {
		// 	args.field_filters.item_group = [args.field_filters.item_group.at(-1)];
		// }
		this.disable_view_toggler(true);
		frappe.call({
			method: "webshop.webshop.api.get_product_filter_data",
			args: {
				query_args: args,
			},
			loading: true,
			callback: function(result) {
				console.log('received',result)
				if (!result || result.exc || !result.message || result.message.exc) {
					me.render_no_products_section(true);
					frappe.show_alert({
						message: 'Sorry! No items match your filter',
						indicator: 'red'
					},5); // 5 seconds duration
				} else {
					// Sub Category results are independent of Items
					if (me.item_group && result.message["sub_categories"].length) {
						me.render_item_sub_categories(result.message["sub_categories"])
					}
					if (!result.message["items"].length) {
						// if result has no items or result is empty
						frappe.show_alert({
							message: 'Sorry! No items match your filter',
							indicator: 'red'
						},5); // 5 seconds duration
						me.render_no_products_section();
						
					}else{
						updateBrandBreadcrumbs();
						// Add discount filters
						//me.re_render_discount_filters(result.message["filters"].discount_filters);
						// Render views
						me.render_list_view(result.message["items"], result.message["settings"]);
						me.render_grid_view(result.message["items"], result.message["settings"]);
						

						me.products = result.message["items"];
						me.product_count = result.message["items_count"];

						let new_brand_filters = result.message['valid_filters'].filter(item => item.hasOwnProperty('brand')).map(item => item.brand).flat();
						let	new_attribute_filters = result.message['valid_filters'].filter(item => !item.hasOwnProperty('brand') && !item.hasOwnProperty('item_group'));
						let new_item_group_filters = result.message['valid_filters'].filter(item => item.hasOwnProperty('item_group')).map(item => item.item_group).flat(); 
						
						let isFrom = args.item_group ? "Category":"Brand";
						let isLeafNode = result.message['valid_filters'].filter(item=>item.hasOwnProperty('leaf_node')).map(item => item.leaf_node)[0];
						//console.log({"LeafNode":isLeafNode})
						setDynamicFiltersForItemGroup(isFrom,Object.keys(args.field_filters).length,Object.keys(args.attribute_filters).length);


						let prev_brand_filters = args.field_filters.hasOwnProperty('brand') ? args.field_filters.brand:[]
						//console.log('prev_brand_filters, - ',prev_brand_filters)
						let prev_attribute_filters = args['attribute_filters'] ? Object.keys(args['attribute_filters']) :[]
						//console.log('prev_attribute_filters,-' + prev_attribute_filters)
						let	prev_item_group_filters = args.field_filters.hasOwnProperty('item_group')? args.field_filters.item_group : []
						//console.log('prev_item_group_filters,-' + prev_item_group_filters)
						//console.log({prev_attr:prev_attribute_filters})

						if(result.message['valid_filters'].length > 0 && result.message["items_count"] > 1){
							me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,new_attribute_filters,prev_attribute_filters,isFrom,isLeafNode,from_filters)
						}else{
							me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,[],prev_attribute_filters,isFrom,isLeafNode,from_filters)

						}

						// if(result.message['valid_filters'].length > 0 && result.message["items_count"] > 1){
						// 	if(result.message["sub_categories"].length == 0 && args['item_group']){
						// 		console.log('leaf node filters value > 1')
						// 		me.set_valid_attribute_filters([],[],new_brand_filters,prev_brand_filters,new_attribute_filters,prev_attribute_filters,isFrom,isLeafNode,from_filters)

						// 	}
						// 	else if(result.message["sub_categories"].length > 0 && args['item_group']){
						// 		console.log('sub group filters > 1')
						// 		if(from_filters){
						// 			me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,new_attribute_filters,prev_attribute_filters,isFrom,isLeafNode,from_filters)
						// 		}else{
						// 			me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,new_attribute_filters,prev_attribute_filters,isFrom,isLeafNode,from_filters)

						// 		}
						// 	}
						// 	else if(result.message["sub_categories"].length == 0 && !args['item_group']){
						// 		// let new_item_group_filters = result.message['valid_filters'].filter(item => item.hasOwnProperty('item_group')).map(item => item.item_group).flat();
						// 		// let prev_item_group_filters = args.field_filters.hasOwnProperty('item_group') ? args.field_filters['item_group']:[]
						// 		if(from_filters){
						// 		console.log('All products Page value > 1')
						// 		me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,new_attribute_filters,prev_attribute_filters,isFrom,isLeafNode,from_filters)
						// 		}else{
						// 			console.log('All products Page value > 1 without filters')
						// 			// prev_item_group_filters = [prev_item_group_filters.at(-1)]
						// 			// console.log(prev_item_group_filters)

						// 			me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,new_attribute_filters,prev_attribute_filters,isFrom,isLeafNode,from_filters)
						// 		}

						// 	}
						// }
						// else if(result.message['valid_filters'].length > 0 && result.message["items_count"] == 1){
						// 	if(result.message["sub_categories"].length == 0 && args['item_group']){
						// 		console.log('leaf node filters value < 1')
						// 		me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,[],prev_attribute_filters,isFrom,isLeafNode,from_filters)

						// 	}else if(result.message["sub_categories"].length > 0 && args['item_group']){
						// 		console.log('sub group filters < 1')
						// 		me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,[],prev_attribute_filters,isFrom,isLeafNode,from_filters)
								
						// 	}
						// 	else if (result.message["sub_categories"].length == 0  && from_filters && !args['item_group']){
						// 		console.log('All products Page value < 1')
						// 		me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,[],prev_attribute_filters,isFrom,isLeafNode,from_filters)
						// 	}else if (result.message["sub_categories"].length == 0  && !from_filters && !args['item_group']){
						// 		console.log('All products Page value < 1')
						// 		me.set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,[],prev_attribute_filters,isFrom,isLeafNode,from_filters)
						// 	}

						// }
						
					}
					if (!from_filters) {
						// If `get_product_filter_data` was triggered after checking a filter,
						// don't touch filters unnecessarily, only data must change
						// filter persistence is handle on filter change event
						me.bind_filters();
						me.restore_filters_state();
					
						//console.log('New Restore filter Action')
					}
					
					// Bottom paging
					me.add_paging_section(result.message["settings"]);
					me.disable_view_toggler(false);
				}

				
			}
		});
		console.timeEnd('Getting Filtered Data')
	}

	disable_view_toggler(disable=false) {
		$('#list').prop('disabled', disable);
		$('#image-view').prop('disabled', disable);
	}

	render_grid_view(items, settings) {
		// loop over data and add grid html to it
		let me = this;
		this.prepare_product_area_wrapper("grid");
		//let exclude_out_of_stock = $('.filter-options .field-filter-stock:checked').data('filter-value') == 'Exclude Out Of Stock' ? 1 : 0
		//console.log(exclude_out_of_stock)
		new webshop.ProductGrid({
				items: items, //exclude_out_of_stock ? items.filter(i => i.in_stock > 0):
				products_section: $("#products-grid-area"),
				settings: settings,
				preference: me.preference
		});

	}

	render_list_view(items, settings) {
		let me = this;
		this.prepare_product_area_wrapper("list");
		//let exclude_out_of_stock = $('.filter-options .field-filter-stock:checked').data('filter-value') == 'Exclude Out Of Stock' ? 1 : 0
		//console.log(exclude_out_of_stock)
		new webshop.ProductList({
			items: items, //exclude_out_of_stock ? items.filter(i => i.in_stock > 0):
			products_section: $("#products-list-area"),
			settings: settings,
			preference: me.preference
		});
	}

	prepare_product_area_wrapper(view) {
		let left_margin = view == "list" ? "ml-2" : "";
		let top_margin = view == "list" ? "mt-6" : "mt-minus-1";
		return this.products_section.append(`
			<br>
			<div id="products-${view}-area" class="row products-list ${ top_margin } ${ left_margin }" itemscope itemtype="https://schema.org/Product"></div>
		`);
	}

	get_query_filters() {
		const filters = frappe.utils.get_query_params();
		let {field_filters, attribute_filters} = filters;

		field_filters = field_filters ? JSON.parse(field_filters) : {};
		attribute_filters = attribute_filters ? JSON.parse(attribute_filters) : {};
		//console.log('restore filter')
		$('#sort-options').val($('.filter-options .field-filter-custom_s1_sorting:checked').data('filter-value'))

		return {
			field_filters: field_filters,
			attribute_filters: attribute_filters,
			item_group: this.item_group,
			start: filters.start || null,
			from_filters: this.from_filters || false
		};
	}

	add_paging_section(settings) {
		$(".product-paging-area").remove();
		let tot_item = {};
		if (this.products) {
			let paging_html = `
				<div class="row product-paging-area mt-5">
					<div class="col-3">
					</div>
					<div class="col-9 text-right">
			`;
			let query_params = frappe.utils.get_query_params();
			let start = query_params.start ? cint(JSON.parse(query_params.start)) : 0;
			let page_length = settings.products_per_page || 0;

			let prev_disable = start > 0 ? "" : "disabled";
			let next_disable = (this.product_count > page_length) ? "" : "disabled";
			//newly added
			let page_no = (start / page_length)+1
			let tot_page_no = Math.ceil((start+this.product_count)/page_length)
			/*
			paging_html += `
				<button class="btn btn-default btn-prev" data-start="${ start - page_length }"
					style="float: left" ${prev_disable}>
					${ __("Prev") }
				</button>`;

			paging_html += `
				<button class="btn btn-default btn-next" data-start="${ start + page_length }"
					${next_disable}>
					${ __("Next") }
				</button>
			`;
			*/
			paging_html += `
				<button class="btn btn-default btn-prev" data-start="${ start - page_length }"
					style="background-color:#000000; float: left; color:#FFFFFF;" ${prev_disable}>
					${ __("Prev") }
				</button>
				<button class="btn btn-default" style="background-color:#000000; float: left; color:#FFFFFF; margin-left:30%">
				${page_no+"/"+tot_page_no}
				</button>
				<button class="btn btn-default" id="page-detail" style="float: left; margin-left:5px" hidden>${page_length+"-"+tot_page_no}
				</button>`;

			 /*<button class="btn btn-primary btn-home" id="product-home" style="display: inline-block; float: left; margin-left: 25%;">
				Home
				</button>

				<button class="btn btn-success btn-last" id="product-last" style="display: inline-block; float: left; margin-left: 1%;">
					Last
				</button>*/
			paging_html += `
				<span style="display: inline-block; border: 1px solid #000; border-radius: 0; box-shadow: none;float:left;margin-left:5%">
					<input type="text" class="form-control" id="input-pageno" style="width: 60px; height: 28px; display: inline-block; border: 1px solid #ccc; border-radius: 0; box-shadow: none; margin-right: 0; background-color: white; padding: 6px 12px; vertical-align: middle;" maxlength="4" placeholder="1,2...">

					<button class='btn btn-go' id="goto-pageno" style="display: inline-block; height: 28px; border: 1px solid #ccc; padding: 2px 12px; background-color: #000000; color:#fff; box-shadow: none; margin-left: -10px; border-radius: 0; vertical-align: middle;">
						Go
					</button>
				</span>
				<button class="btn btn-default btn-next" data-start="${ start + page_length }"
					style="background-color:#000000; border: 1px solid #000;color:#FFFFFF;" ${next_disable}>
					${ __("Next") }
				</button>
			`;

			paging_html += `</div></div>`;

			$(".page_content").append(paging_html);
			this.bind_paging_action();
			this.goto_pageno();
		}
	}

	prepare_search() {
		if(! $('.top-search')){
		$("#navbarSupportedContent").prepend(`
				<div class="dropdown top-search" id="dropdownMenuSearch" style="width: 40%; max-height: 300px">
					<input type="search" name="query" id="search-box" class="form-control font-md"
						placeholder="Search for Products"
						aria-label="Product" aria-describedby="button-addon2">
					<div class="search-icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round"
							stroke-linejoin="round"
							class="feather feather-search">
							<circle cx="11" cy="11" r="8"></circle>
							<line x1="21" y1="21" x2="16.65" y2="16.65"></line>
						</svg>
					</div>
					<!-- Results dropdown rendered in product_search.js -->
				</div>
		`);
		}
	}

	render_view_toggler() {
		$(".toolbar").append(`<div class="toggle-container col-12 p-0"></div>`);
		["btn-list-view", "btn-grid-view"].forEach(view => {
			let icon = view === "btn-list-view" ? "list" : "image-view";
			$(".toggle-container").append(`
				<div class="form-group mb-0" id="toggle-view">
					<button id="${ icon }" class="btn ${ view } mr-2">
						<span>
							<svg class="icon icon-md">
								<use href="#icon-${ icon }"></use>
							</svg>
						</span>
					</button>
				</div>
			`);
		});

		$(".toggle-container").append(`
    <div class="form-group card mb-0 filters-section" id="toggle-view" style="margin-right: 10px; margin-top:-5px">
        <div class="card-body p-2 filter-lookup-wrapper filter-custom_s1_sorting">
            <select id="sort-options" name="options" class="form-control" style="padding-right: 30px;">
                <option value="" disabled selected>Sort By</option>
				<option  value="Part No - A ↑ Z">Part No - A ↑ Z</option>
				<option  value="Part No - Z ↓ A">Part No - Z ↓ A</option>
				<option  value="Price - High ↓ Low">Price - High ↓ Low</option>
				<option  value="Price - Low ↑ High">Price - Low ↑ High</option>
			</select>
            <!-- Clear button -->
            <button id="clear-sort" type="button" style="
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                background: transparent;
                border: none;
                font-size: 16px;
                cursor: pointer;
            ">&times;</button>
        </div>
    </div>
		`);		
		
	}

	bind_view_toggler_actions() {
		$("#list").click(function() {
			let $btn = $(this);
			$btn.removeClass('btn-primary');
			$btn.addClass('btn-primary');
			$(".btn-grid-view").removeClass('btn-primary');

			$("#products-grid-area").addClass("hidden");
			$("#products-list-area").removeClass("hidden");
			localStorage.setItem("product_view", "List View");
		});

		$("#image-view").click(function() {
			let $btn = $(this);
			$btn.removeClass('btn-primary');
			$btn.addClass('btn-primary');
			$(".btn-list-view").removeClass('btn-primary');

			$("#products-list-area").addClass("hidden");
			$("#products-grid-area").removeClass("hidden");
			localStorage.setItem("product_view", "Grid View");
		});
		$('#clear-sort').click(function (e) {
			e.preventDefault()
			$('#sort-options').prop('selectedIndex', 0);
			$('.filter-options .field-filter-custom_s1_sorting:checked').prop('checked', false).trigger('change');
			 // Reset dropdown to default
		});
		$('#sort-options').on('change', function (e) {
			e.preventDefault()
			console.log('Teststs')
			// Get the selected value from the dropdown
			let selectedValue = $(this).val();
			$(`input[name="custom_s1_sorting"][data-filter-value="${selectedValue}"]`).prop('checked', true).trigger('change');
			console.log(selectedValue)
			// Find the radio button with the same value and select it
			//$('input[type="radio"][value="' + selectedValue + '"]').prop('checked', true);
		});
	}

	set_view_state() {
		if (this.preference === "List View") {
			$("#list").addClass('btn-primary');
			$("#image-view").removeClass('btn-primary');
		} else {
			$("#image-view").addClass('btn-primary');
			$("#list").removeClass('btn-primary');
		}

	}

	bind_paging_action() {
		let me = this;
		$('.btn-prev, .btn-next').click((e) => {
			const $btn = $(e.target);
			me.from_filters = false;

			$btn.prop('disabled', true);
			const start = $btn.data('start');

			let query_params = frappe.utils.get_query_params();
			query_params.start = start;
			let path = window.location.pathname + '?' + frappe.utils.get_url_from_dict(query_params);
			window.location.href = path;
		});
	}

	re_render_discount_filters(filter_data) {
		this.get_discount_filter_html(filter_data);
		if (this.from_filters) {
			// Bind filter action if triggered via filters
			// if not from filter action, page load will bind actions
			this.bind_discount_filter_action();
		}
		// discount filters are rendered with Items (later)
		// unlike the other filters
		this.restore_discount_filter();
	}

	get_discount_filter_html(filter_data) {
		$("#discount-filters").remove();
		if (filter_data) {
			$("#product-filters").append(`
				<div id="discount-filters" class="mb-4 filter-block pb-5 hidden">
					<div class="filter-label mb-3">${ __("Discounts") }</div>
				</div>
			`);

			let html = `<div class="filter-options">`;
			filter_data.forEach(filter => {
				html += `
					<div class="checkbox">
						<label data-value="${ filter[0] }">
							<input type="radio"
								class="product-filter discount-filter"
								name="discount" id="${ filter[0] }"
								data-filter-name="discount"
								data-filter-value="${ filter[0] }"
								style="width: 14px !important"
							>
								<span class="label-area" for="${ filter[0] }">
									${ filter[1] }
								</span>
						</label>
					</div>
				`;
			});
			html += `</div>`;

			//$("#discount-filters").append(html);
		}
	}

	restore_discount_filter() {
		const filters = frappe.utils.get_query_params();
		let field_filters = filters.field_filters;
		if (!field_filters) return;

		field_filters = JSON.parse(field_filters);

		if (field_filters && field_filters["discount"]) {
			const values = field_filters["discount"];
			const selector = values.map(value => {
				return `input[data-filter-name="discount"][data-filter-value="${value}"]`;
			}).join(',');
			$(selector).prop('checked', true);
			this.field_filters = field_filters;

		}
	}
	bind_filters() {
		let me = this;
		this.field_filters = {};
		this.attribute_filters = {};

		$('.product-filter').on('change', (e) => {
			e.preventDefault()
			me.from_filters = true;

			const $checkbox = $(e.target);
			const is_checked = $checkbox.is(':checked');

			if ($checkbox.is('.attribute-filter')) {
				const {
					attributeName: attribute_name,
					attributeValue: attribute_value
				} = $checkbox.data();

				if (is_checked) {
					this.attribute_filters[attribute_name] = this.attribute_filters[attribute_name] || [];
					this.attribute_filters[attribute_name].push(attribute_value);
				} else {
					this.attribute_filters[attribute_name] = this.attribute_filters[attribute_name] || [];
					this.attribute_filters[attribute_name] = this.attribute_filters[attribute_name].filter(v => v !== attribute_value);
				}

				if (this.attribute_filters[attribute_name].length === 0) {
					delete this.attribute_filters[attribute_name];
				}
			} else if ($checkbox.is('.field-filter') || $checkbox.is('.discount-filter')) {
				const {
					filterName: filter_name,
					filterValue: filter_value
				} = $checkbox.data();

				if ($checkbox.is('.discount-filter')) {
					// clear previous discount filter to accomodate new
					delete this.field_filters["discount"];
					
				}
				if (is_checked) {
					if(this.field_filters[filter_name] === 'custom_s1_sorting'){
						this.field_filters[filter_name] = this.field_filters[filter_name] || [];
						if (!in_list(this.field_filters[filter_name], filter_value)) {
							this.field_filters[filter_name] = [filter_value];
							//console.log(this.field_filters[filter_name])
						}
					}else{
					this.field_filters[filter_name] = this.field_filters[filter_name] || [];
					if (!in_list(this.field_filters[filter_name], filter_value)) {
						this.field_filters[filter_name].push(filter_value);
					}
				}
				} else {
					this.field_filters[filter_name] = this.field_filters[filter_name] || [];
					this.field_filters[filter_name] = this.field_filters[filter_name].filter(v => v !== filter_value);
				}

				if (this.field_filters[filter_name].length === 0) {
					delete this.field_filters[filter_name];
				}
			}
			
			me.change_route_with_filters();
		});


		// bind filter lookup input box
		$('.filter-lookup-input').on('keydown', frappe.utils.debounce((e) => {
			const $input = $(e.target);
			const keyword = ($input.val() || '').toLowerCase();
			const $filter_options = $input.next('.filter-options');

			$filter_options.find('.filter-lookup-wrapper').show();
			$filter_options.find('.filter-lookup-wrapper').each((i, el) => {
				const $el = $(el);
				const value = $el.data('value').toLowerCase();
				if (!value.includes(keyword)) {
					$el.hide();
				}
			});
		}, 300));
	}

	change_route_with_filters() {
		console.log('change_route_with_filters')
		let route_params = frappe.utils.get_query_params();

		let start = this.if_key_exists(route_params.start) || 0;
		if (this.from_filters) {
			start = 0; // show items from first page if new filters are triggered
		}
		
		this.field_filters.custom_s1_sorting = [$('.filter-options .field-filter-custom_s1_sorting:checked').data('filter-value')]
		const query_string = this.get_query_string({
			start: start,
			field_filters: JSON.stringify(this.if_key_exists(this.field_filters)),
			attribute_filters: JSON.stringify(this.if_key_exists(this.attribute_filters)),
		});
		window.history.pushState('filters', '', `${location.pathname}?` + query_string);

		$('.page_content input').prop('disabled', true);
		
		this.make(true);
		$('.page_content input').prop('disabled', false);
		
	}


	restore_filters_state() {
		const filters = frappe.utils.get_query_params();
		let {field_filters, attribute_filters} = filters;

		if (field_filters) {
			field_filters = JSON.parse(field_filters);
			for (let fieldname in field_filters) {
				const values = field_filters[fieldname];
				const selector = values.map(value => {
					return `input[data-filter-name="${fieldname}"][data-filter-value="${value}"]`;
				}).join(',');
				$(selector).prop('checked', true);
			}
			this.field_filters = field_filters;
			//console.log(field_filters)
			//console.log('restore filter2')
		$('#sort-options').val($('.filter-options .field-filter-custom_s1_sorting:checked').data('filter-value'))
		}
		if (attribute_filters) {

			attribute_filters = JSON.parse(attribute_filters);
			for (let attribute in attribute_filters) {
				const values = attribute_filters[attribute];
				const selector = values.map(value => {
					return `input[data-attribute-name="${attribute}"][data-attribute-value="${value}"]`;
				}).join(',');
				$(selector).prop('checked', true);
			}
			this.attribute_filters = attribute_filters;
			//console.log('attr_restore_filters',attribute_filters)
		}
	}

	render_no_products_section(error=false) {
		let error_section = `
			<div class="mt-4 w-100 alert alert-error font-md">
				Something went wrong. Please refresh the page or contact Source1 Distribution support at esales@s1distribution.com for assistance.
			</div>
		`;
		let no_results_section = `
			<div class="cart-empty frappe-card mt-4">
				<div class="cart-empty-state">
					<img src="/assets/webshop/images/cart-empty-state.png" alt="Empty Cart">
				</div>
				<div class="cart-empty-message mt-4">${ __('No products found') }</p>
			</div>
		`;

		this.products_section.append(error ? error_section : no_results_section);
	}

	render_item_sub_categories(categories) {
		if (categories && categories.length) {
			// Check if the container already exists
			let subCategoryContainer = $("#product-listing .sub-category-container");
			
			// If it exists, clear its contents; otherwise, create a new container
			if (!subCategoryContainer.length) {
				subCategoryContainer = $(`
					<div class="sub-category-container scroll-categories"></div>
				`);
				$("#product-listing").prepend(subCategoryContainer);
			} else {
				subCategoryContainer.empty();
			}
	
			// Build the HTML for the sub-categories
			let sub_group_html = '';
			categories.forEach(category => {
				sub_group_html += `
					<a href="/${ category.route || '#' }" style="text-decoration: none;">
						<div class="category-pill">
							${ category.name }
						</div>
					</a>
				`;
			});
	
			// Append the updated HTML to the container
			subCategoryContainer.html(sub_group_html);
		}
	}

	get_prev_attribute_filter_values(prev_attr_list){
		let output = [];

		prev_attr_list.forEach(function (attr) {
			// Find the respective filter block based on the attribute name
			var filterBlock = $(".filter-block").filter(function () {
				return $(this).data("value") === attr;
			});
	
			if (filterBlock.length) {
				var itemAttributeValues = [];
	
				// Loop through all input elements in the filter block to get the data-attribute-value
				filterBlock.find(".filter-lookup-wrapper input").each(function () {
					itemAttributeValues.push($(this).data("attribute-value"));
				});
	
				// Push the result into the output array
				output.push({
					name: attr,
					item_attribute_values: itemAttributeValues
				});
			}
		});
	
		//console.log(output);
		return 	output
	}
	

	get_query_string(object) {
		const url = new URLSearchParams();
		for (let key in object) {
			const value = object[key];
			if (value) {
				url.append(key, value);
			}
		}
		return url.toString();
	}

	if_key_exists(obj) {
		let exists = false;
		for (let key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
				exists = true;
				break;
			}
		}
		return exists ? obj : undefined;
	}

	//page redirection newly added
	goto_pageno(){
		$('.btn-go').click(function() {
			let currentUrl = new URL(window.location.href);
			let page_detail = document.getElementById('page-detail').innerText;
			let page_length = Number(page_detail.split('-')[0]);
			let tot_page = Number(page_detail.split('-')[1].trim());
			let page_no = Number(document.getElementById('input-pageno').value);
			if(page_no > 0 && page_no <= tot_page){
			currentUrl.searchParams.set('start', (page_no * page_length) - page_length);
			window.location.href = currentUrl.toString();
			}else{
				frappe.msgprint({message:`Please enter valid Page No...`})//From 1 To ${tot_page}
			}
		});
	}





	set_valid_attribute_filters(new_item_group_filters,prev_item_group_filters,new_brand_filters,prev_brand_filters,new_attribute_filters,prev_attribute_filters,isFrom,leaf_node=0,from_filters,items_count) {
		console.log('html',$('.attr-filter').length)
		console.log('prev_attr_filter',prev_attribute_filters.length)
		console.log('prev_brand_filter',prev_brand_filters.length)
		console.log('prev_fitem_group_filter',prev_item_group_filters.length)
		if (from_filters == false && (prev_attribute_filters.length > 0 || prev_brand_filters.length > 0 || prev_item_group_filters.length > 0)) {
			let storedAttrHTML = sessionStorage.getItem('prev_attr_filter_for_load');
			if (storedAttrHTML !== undefined) {
				if($('#attr-filter-section').length != 0){
					$('#attr-filter-section').html(storedAttrHTML);
					}else if($('#attr-filter-section').length == 0){
						let attr_section = document.createElement('div')
						attr_section.classList.add('mb-4', 'filter-block', 'attr-filter-section', 'pb-5');
						attr_section.id ="attr-filter-section"
						$('.filter-block:last').after(attr_section);
						$('#attr-filter-section').html(storedAttrHTML);
						
					}
				console.log('Stored Attr HTML',storedAttrHTML)
				console.log('do not Update ATTR Filters')
			}
			set_new_attribute_filters(new_attribute_filters, prev_attribute_filters,leaf_node,isFrom, from_filters)
			set_new_valid_brand_filters(new_brand_filters,prev_brand_filters,leaf_node,isFrom,from_filters);
			set_new_item_group_filters(new_item_group_filters,prev_item_group_filters,leaf_node,isFrom,leaf_node,from_filters)//:[];

		}else{
		 console.log('Update Filters')
		// // Remove existing attribute filters
		// let selectorToKeep = prev_attribute_filters.map(key => `[data-value="${key}"]`).join(',');
		// $('.attr-filter').not(selectorToKeep).remove();
		// console.log({new_attr:new_attribute_filters,prev_attr:prev_attribute_filters})
		// sessionStorage.setItem('prev_attr_filter_for_load', $('#attr-filter-section').html());
		// new_attribute_filters
		// 	.filter(attr => !prev_attribute_filters.includes(attr.name))
		// 	.sort((a, b) => a.name.localeCompare(b.name))
		// 	.forEach(attribute => {
		// 		const html = build_attribute_filter_html(attribute);
		// 		if($('#attr-filter-section').length != 0){
		// 		$('#attr-filter-section').append(html);
		// 		}else if($('#attr-filter-section').length == 0){
		// 			let attr_section = document.createElement('div')
		// 			attr_section.classList.add('mb-4', 'filter-block', 'attr-filter-section', 'pb-5');
		// 			attr_section.id ="attr-filter-section"
		// 			$('.filter-block:last').after(attr_section);
		// 			$('#attr-filter-section').append(html);
					
		// 		}
		// 	});
	
		set_new_attribute_filters(new_attribute_filters, prev_attribute_filters, isFrom, from_filters)
		// Update brand filters
		set_new_valid_brand_filters(new_brand_filters,prev_brand_filters,leaf_node,isFrom,from_filters);
		// new_item_group_filters.length > 0 && 
		set_new_item_group_filters(new_item_group_filters,prev_item_group_filters,leaf_node,isFrom,leaf_node,from_filters)//:[];
		// prev_item_group_filters.length ==1 ?  set_new_item_group_filters(new_item_group_filters,prev_item_group_filters) :console.log('Not Prev Items')
		// updateBrandBreadcrumbs();
	
		// Attach event listeners to product filters
		$('.product-filter').off('change').on('change', (e) => {this.handle_filter_change(e)});
		this.restore_filters_state()
		}
	}

	
	handle_filter_change(e) {
		e.preventDefault()
		const $checkbox = $(e.target);
		const is_checked = $checkbox.is(':checked');
		const data = $checkbox.data();
	
		if ($checkbox.hasClass('attribute-filter')) {
			this.update_filters(this.attribute_filters, data.attributeName, data.attributeValue, is_checked);
		} else if ($checkbox.hasClass('field-filter') || $checkbox.hasClass('discount-filter')) {
			if ($checkbox.hasClass('discount-filter')) delete this.field_filters["discount"];
			this.update_filters(this.field_filters, data.filterName, data.filterValue, is_checked);
		}
	
		this.change_route_with_filters();
		
		

	}
	
	update_filters(filters, filter_name, filter_value, is_checked) {
		filters[filter_name] = filters[filter_name] || [];
		if (is_checked) {
			if (!filters[filter_name].includes(filter_value)) filters[filter_name].push(filter_value);
		} else {
			filters[filter_name] = filters[filter_name].filter(value => value !== filter_value);
			if (filters[filter_name].length === 0) delete filters[filter_name];
		}
	}

}	

function set_new_attribute_filters(new_attribute_filters, prev_attribute_filters,leaf_node, isFrom, from_filters) {
	// Remove existing attribute filters
	const selectorToKeep = prev_attribute_filters.map(key => `[data-value="${key}"]`).join(',');
	$('.attr-filter').not(selectorToKeep).remove();
    selectorToKeep.length > 0 ? sessionStorage.setItem('prev_attr_filter_for_load', $('#attr-filter-section').html()): ''
	// Render new attribute filters
	new_attribute_filters
		.filter(attr => !prev_attribute_filters.includes(attr.name))
		.sort((a, b) => a.name.localeCompare(b.name))
		.forEach(attribute => {
			const html = build_attribute_filter_html(attribute);
			$('#attr-filter-section').append(html);
		});
}

function set_new_valid_brand_filters(new_brand_filters,prev_brand_filters,leaf_node,isFrom,from_filters) {
	// Remove existing brand filters
	const selectorToKeep = prev_brand_filters.map(key => `[data-value="${key}"]`).join(',');
	let removed = $('.filter-brand').not(selectorToKeep).remove();
	console.log('selectorToKeep-',selectorToKeep)
	selectorToKeep.length > 0 ? sessionStorage.setItem('prev_brand_filter_for_load' , $('.filter-brand-section').html()) : ''
	// Render new brand filters
	new_brand_filters
		.filter(brand => !prev_brand_filters.includes(brand))
		.sort()
		.forEach(brand_name => {
			const html = `
				<div class="filter-lookup-wrapper filter-brand" data-value="${brand_name}">
					<label>
						<input type="checkbox" class="product-filter field-filter field-filter-brand"
							data-filter-name="brand" data-filter-value="${brand_name}" style="width: 14px;" >
						<span class="label-area">${brand_name}</span>
					</label>
				</div>`;
			$('.filter-brand-section').append(html);
		});
		if($('.filter-brand-section').length == 0){
			//$('.filter-brand-section').prepend(sessionStorage.getItem('prev_brand_filter_for_load'))
		}else{
			//$('.filter-brand-section').prepend(sessionStorage.getItem('prev_brand_filter_for_load'))
		}
	
	
	setDynamicFiltersForBrands(new_brand_filters,prev_brand_filters,isFrom)
}

function updateBrandBreadcrumbs() {
	//console.log("BreadCrumbs")
	const filters = frappe.utils.get_query_params();
	let { field_filters } = filters;
	field_filters = field_filters ? JSON.parse(field_filters) : {};
	const brands = field_filters.brand || [];
	const itemGroups = field_filters.item_group || [];
	if (window.location.pathname === '/brands' && (brands.length || itemGroups.length)) {
        // Static breadcrumbs for Home and Brands
        let breadcrumbs = `
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="breadcrumb-item">
                <a itemprop="item" href="/">
                    <span itemprop="name">Home</span>
                    <meta itemprop="position" content="1">
                </a>
            </li>
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="breadcrumb-item">
                <a itemprop="item" href="/shop-by-category">
                    <span itemprop="name">Brands</span>
                    <meta itemprop="position" content="2">
                </a>
            </li>`;

        // Combine brands and item groups for breadcrumb generation
        const allNodes = [...brands, ...itemGroups];
        const baseFilters = { brand: brands };

        allNodes.forEach((node, index) => {
            let isLastNode = index === allNodes.length - 1;
            let nodeHref = "";
            if (!isLastNode) {
                // Construct href for intermediate breadcrumbs
                baseFilters.item_group = itemGroups.slice(0, index + 1 - brands.length);
                nodeHref = `/brands?field_filters=${encodeURIComponent(JSON.stringify(baseFilters))}`;
            }

            breadcrumbs += `
                <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="breadcrumb-item ${isLastNode ? 'active' : ''}">
                    ${isLastNode ? `
                        <span itemprop="item">
                            <span itemprop="name">${node}</span>
                            <meta itemprop="position" content="${index + 3}">
                        </span>
                    ` : `
                        <a href="${nodeHref}">
                            <span itemprop="item">
                                <span itemprop="name">${node}</span>
                                <meta itemprop="position" content="${index + 3}">
                            </span>
                        </a>
                    `}
                </li>`;
        });

        // Update breadcrumbs in the DOM
        $(".breadcrumb").html(breadcrumbs);

        // Ensure proper navigation for non-active breadcrumbs
        $(".breadcrumb a").on("click", function (e) {
            const href = $(this).attr("href");
            if (href) {
                window.location.href = href;
            }
        });
    }
}


function set_new_item_group_filters(new_item_group_filters, prev_item_group_filters,leaf_node, isFrom, leafNode,from_filters) {
    //console.log('Set Item Groups');
	//let prev_item_group_html = $('.filter-item_group-section').html()
    if (isFrom === "Brand") {
		// new_item_group_filters = leafNode && new_item_group_filters==0?[prev_item_group_filters.at(-1)]:new_item_group_filters
		// prev_item_group_filters = leafNode?[]:prev_item_group_filters
		//console.log({hideItemGroup:leafNode && (new_item_group_filters==0 || new_item_group_filters.length==1)})
		leafNode && (new_item_group_filters==0 || new_item_group_filters.length==1)?$('.item_group-collapsible').closest('.filter-block').attr('hidden', true):"";
		const selectorToKeep = prev_item_group_filters.map(key => `[data-value="${key}"]`).join(',');
        new_item_group_filters.length > 0 ? $('.filter-item_group').filter(selectorToKeep).remove():""; //|| new_item_group_filters.length ==1
		$('.filter-item_group').not(selectorToKeep).remove();
		sessionStorage.setItem('prev_item_group_filter_for_load', $('.filter-item_group-section').html()) ;

		//console.log({"0th Group":selectorToKeep});
    }else{
		const selectorToKeep = prev_item_group_filters.map(key => `[data-value="${key}"]`).join(',');
    	$('.filter-item_group').not(selectorToKeep).remove();
		sessionStorage.setItem('prev_item_group_filter_for_load', $('.filter-item_group-section').html());
	}
	
    //console.log({"new_item_group":new_item_group_filters,"prev_item_group":prev_item_group_filters});
	const hideInput = isFrom === "Brand"?'hidden':'';
	const labelStyle = isFrom === "Brand" 
    ? 'cursor: pointer;' 
    : '';
	const labelAttribute = isFrom === "Brand"
    ? 'onmouseover="this.style.color=\'red\';" onmouseout="this.style.color=\'\';"'
    : '';

    // Determine the disabled attribute based on leafNode
    // const disabledAttribute = leafNode && (new_item_group_filters.length==0 || new_item_group_filters.length==1) ? 'disabled' : '';

    // Render new brand filters
    new_item_group_filters
        .filter(item_group => !prev_item_group_filters.includes(item_group))
        .sort()
        .forEach(item_group_name => {
            const html = `
                <div class="filter-lookup-wrapper filter-item_group" data-value="${item_group_name}">
                    <label>
                        <input type="checkbox" class="product-filter field-filter field-filter-item_group"
                            data-filter-name="item_group" data-filter-value="${item_group_name}" style="width: 14px;" ${hideInput}> 
                        <span class="label-area" style="${labelStyle}" ${labelAttribute}>${item_group_name}</span>
                    </label>
                </div>`;
            $('.filter-item_group-section').append(html);
        });
		//from_filters == false && prev_item_group_filters.length > 0 ? $('.filter-item_group-section').prepend(sessionStorage.getItem('prev_item_group_filter_for_load')) : console.log('No Need to update ITem group Filter')
}


function setDynamicFiltersForBrands(new_brand_filters,prev_brand_filters,isFrom){
	if(isFrom=="Brand" && (new_brand_filters.length==1 || prev_brand_filters.length==1)){
		$('.brand-collapsible').closest('.filter-block').attr('hidden', true);
	}
}

function setDynamicFiltersForItemGroup(isFrom,field_length,attr_length){
	if(isFrom == 'Brand' && field_length==0 && attr_length==0){
	$('.item_group-collapsible').closest('.filter-block').attr('hidden', true);
	}else if(isFrom == 'Brand' && (field_length > 0 || attr_length > 0)){
		$('.item_group-collapsible').closest('.filter-block').attr('hidden', false);
	}
}


function build_attribute_filter_html(attribute) {

		let html = `
			<div class="filter-lookup-wrapper mb-4 filter-block attr-filter pb-5" data-value="${attribute.name}">
				<div class="filter-label mb-3" data-value="${attribute.name}">
					<a id="${attribute.name}">
						<img class="toggle-arrow-attr" src="/files/icons8-down-arrow-30.png" width="20px" height="20px">
					</a> 
					${attribute.name}
				</div>`;
	
		if (attribute.item_attribute_values.length > 10) {
			html += `
				<input type="text" class="form-control form-control-sm mb-2 filter-lookup-input"
					placeholder="Search ${attribute.name}s" 
					oninput="debouncedFilter('${attribute.name}')" 
					style="display:none"/>`;
		}
	
		if (attribute.item_attribute_values.length > 0) {
			let options = attribute.item_attribute_values
				.sort()
				.map(value => `
					<div class="filter-lookup-wrapper checkbox" data-value="${value}">
						<label>
							<input type="checkbox" class="product-filter attribute-filter field-filter"
								data-attribute-name="${attribute.name}" data-attribute-value="${value}">
							<span class="label-area">${value}</span>
						</label>
					</div>`).join('');
			html += `<div class="filter-options hidden" data-value="${attribute.name}" style="max-height: 200px; overflow-y: auto;">
						${options}
						<div class="load-more" style="text-align: center; display: none;">Loading...</div>
					</div>`;
		}else{
		 html += `<p>'No values'</p>`;
		}
	
		html += `</div>`;
		return html;
	}
	




// raw filter code before doing fair - works well

	
// 	set_valid_attribute_filters(new_attribute_filter,new_field_filter,prev_field_filter,prev_attribute_filter) {

// 		// Remove existing attribute filters
// 		const selectorToKeep = prev_attribute_filter.map(key => `[data-value="${key}"]`).join(',');
// 		$('.attr-filter').not(selectorToKeep).remove();
		
	
// 		// Accumulate HTML for all filters
// 		let allFiltersHtml = '';
	
// 		new_attribute_filter.sort((a, b) => (a.name > b.name ? 1 : -1)).forEach(attribute => {
// 			if (!prev_attribute_filter.includes(attribute.name)) {
// 			// Start building the filter block HTML
// 			let html = `<div class="mb-4 filter-block attr-filter pb-5" data-value="${attribute.name}">
// 				<div class="filter-label mb-3" data-value="${attribute.name}">
// 					<a id="${attribute.name}">
// 						<img class="toggle-arrow-attr" src="/files/icons8-down-arrow-30.png" width="20px" height="20px">
// 					</a> 
// 					${attribute.name}
// 				</div>`;
	
// 			// Add inline search input if there are more than 10 values
// 			if (attribute.item_attribute_values.length > 10) {
// 				html += `
// 				<input type="text" 
// 					class="form-control form-control-sm mb-2 filter-lookup-input" 
// 					placeholder="Search ${attribute.name}s" 
// 					oninput="debouncedFilter('${attribute.name}')" 
// 					style="display:none"/>`;
// 			}
	
// 			// Add filter options
// 			if (attribute.item_attribute_values) {
// 				html += `
// 				<div class="filter-options hidden" data-value="${attribute.name}" style="max-height: 200px; overflow-y: auto;">`;
	
// 				// Iterate over attribute values and create checkbox options
// 				attribute.item_attribute_values.sort((a, b) => (a > b ? 1 : -1)).forEach(attr_value => {
// 					html += `
// 					<div class="filter-lookup-wrapper checkbox" data-value="${attr_value}">
// 						<label data-value="${attr_value}">
// 							<input type="checkbox"
// 								class="product-filter attribute-filter"
// 								id="${attr_value}"
// 								data-attribute-name="${attribute.name}"
// 								data-attribute-value="${attr_value}"
// 								${attr_value.checked} ? "checked": '' ">
// 							<span class="label-area">${attr_value}</span>  
// 						</label>
// 					</div>`;
				
// 				});
	
// 				// Add placeholder for loading more values dynamically
// 				html += `
// 					<div class="load-more" style="text-align: center; display: none;">Loading...</div>
// 				</div>`;
// 			}
	
// 			// Close the main filter block
// 			html += `</div>`;

// 			$('#attr-filter-section').append(html)
	
// 			// Append the built HTML for this attribute to the accumulated HTML
// 			allFiltersHtml += html;
// 		}
		
// 		});

// 		set_new_valid_brand_filters(new_field_filter,prev_field_filter)


// 		$('.product-filter').off('change');
// 		$('.product-filter').on('change', (e) => {
// 			e.preventDefault();
// 			const me = this;
// 			me.from_filters = true;
		
// 			const $checkbox = $(e.target);
// 			const is_checked = $checkbox.is(':checked');
		
// 			if ($checkbox.is('.attribute-filter')) {
// 				const {
// 					attributeName: attribute_name,
// 					attributeValue: attribute_value
// 				} = $checkbox.data();
		
// 				if (is_checked) {
// 					this.attribute_filters[attribute_name] = this.attribute_filters[attribute_name] || [];
// 					this.attribute_filters[attribute_name].push(attribute_value);
// 				} else {
// 					this.attribute_filters[attribute_name] = this.attribute_filters[attribute_name] || [];
// 					this.attribute_filters[attribute_name] = this.attribute_filters[attribute_name].filter(v => v !== attribute_value);
// 				}
		
// 				if (this.attribute_filters[attribute_name].length === 0) {
// 					delete this.attribute_filters[attribute_name];
// 				}
// 			} else if ($checkbox.is('.field-filter') || $checkbox.is('.discount-filter')) {
// 				const {
// 					filterName: filter_name,
// 					filterValue: filter_value
// 				} = $checkbox.data();
		
// 				if ($checkbox.is('.discount-filter')) {
// 					// clear previous discount filter to accommodate new
// 					delete this.field_filters["discount"];
// 				}
// 				if (is_checked) {
// 					this.field_filters[filter_name] = this.field_filters[filter_name] || [];
// 					if (!in_list(this.field_filters[filter_name], filter_value)) {
// 						this.field_filters[filter_name].push(filter_value);
// 					}
// 				} else {
// 					this.field_filters[filter_name] = this.field_filters[filter_name] || [];
// 					this.field_filters[filter_name] = this.field_filters[filter_name].filter(v => v !== filter_value);
// 				}
		
// 				if (this.field_filters[filter_name].length === 0) {
// 					delete this.field_filters[filter_name];
// 				}
// 			}
		
// 			me.change_route_with_filters();
// 		});

				
// 	}

// };

// function set_new_valid_brand_filters(new_field_filter,prev_field_filter){
// 	// Remove existing attribute filters

// 	if(prev_field_filter.length > 0){
// 		selectorToKeep = prev_field_filter.map(key => `[data-value="${key}"]`).join(',')
// 		console.log(selectorToKeep + " are not removed")
// 		$('.filter-brand').not(selectorToKeep).remove()
// 	}else{
// 		console.log('all brands removed')
// 		$('.filter-brand').remove()
// 	}
// 	console.log(prev_field_filter,new_field_filter)
// 	new_field_filter.sort((a, b) => (a > b ? 1 : -1)).forEach(brand_name => {
// 	if (!prev_field_filter.includes(brand_name)) {
// 		console.log('test')
// 		let html = `
// 			<div class="filter-lookup-wrapper filter-brand" data-value="${brand_name}">
// 				<label for="${brand_name}">
// 						<input type="checkbox"
// 							class="product-filter field-filter field-filter-brand"
// 							id="${brand_name}"
// 							data-filter-name="brand"
// 							data-filter-value="${brand_name}"
// 							style="width: 14px !important">
// 					<span class="label-area">${brand_name}</span>
// 				</label>
// 			</div>`
// 		$('.filter-brand-section').append(html)
// 	}


// 	})


// }






	// render_item_sub_categories(categories) {
	// 	console.log(categories)
	// 	if (categories && categories.length) {
	// 		let sub_group_html = `
	// 			<div class="sub-category-container scroll-categories">
	// 		`;

	// 		categories.forEach(category => {
	// 			sub_group_html += `
	// 				<a href="/${ category.route || '#' }" style="text-decoration: none;">
	// 					<div class="category-pill">
	// 						${ category.name }
	// 					</div>
	// 				</a>
	// 			`;
	// 		});
	// 		sub_group_html += `</div>`;

	// 		$("#product-listing").prepend(sub_group_html);
	// 	}
	// }

// 	<sup>
// 	<button 
// 		style="
// 		color: white; 
// 		background-color: #dc3545;
// 		border: none; 
// 		border-radius: 50%; 
// 		width: 20px; 
// 		height: 20px; 
// 		display: inline-flex; 
// 		align-items: center; 
// 		justify-content: center; 
// 		font-size: 10px; 
// 		cursor: pointer;"
// 	>
// 	${category.count < 100 ? category.count : '99+'}
// 	</button>
// </sup>

// function set_new_item_group_filters(new_item_group_filters,prev_item_group_filters,isFrom,leafNode) {
// 	console.log('Set Item Groups');
// 	// Remove existing brand filters
// 	const selectorToKeep = prev_item_group_filters.map(key => `[data-value="${key}"]`).join(',')
// 	let removed = $('.filter-item_group').not(selectorToKeep).remove();
// 	isFrom=="Brand" ? $('.filter-item_group').filter(selectorToKeep).remove():"";
// 	console.log(selectorToKeep)
// 	console.log(new_item_group_filters)

// 	// Render new brand filters
// 	if(!leafNode){
// 	new_item_group_filters
// 		.filter(item_group => !prev_item_group_filters.includes(item_group))
// 		.sort()
// 		.forEach(item_group_name => {
// 			const html = `
// 				<div class="filter-lookup-wrapper filter-item_group" data-value="${item_group_name}">
// 					<label>
// 						<input type="checkbox" class="product-filter field-filter field-filter-item_group"
// 							data-filter-name="item_group" data-filter-value="${item_group_name}" style="width: 14px;">
// 						<span class="label-area">${item_group_name}</span>
// 					</label>
// 				</div>`;
// 			$('.filter-item_group-section').append(html);
// 		});
// 	}else{
// 		new_item_group_filters
// 		.filter(item_group => !prev_item_group_filters.includes(item_group))
// 		.sort()
// 		.forEach(item_group_name => {
// 			const html = `
// 				<div class="filter-lookup-wrapper filter-item_group" data-value="${item_group_name}">
// 					<label>
// 						<input type="checkbox" class="product-filter field-filter field-filter-item_group"
// 							data-filter-name="item_group" data-filter-value="${item_group_name}" style="width: 14px;" disabled>
// 						<span class="label-area">${item_group_name}</span>
// 					</label>
// 				</div>`;
// 			$('.filter-item_group-section').append(html);
// 		});
// 	}
// }